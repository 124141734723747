var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          attrs: { color: _vm.getBleu, short: "", app: "" },
          scopedSlots: _vm._u(
            [
              _vm.currentRouteName === "Administration"
                ? {
                    key: "extension",
                    fn: function () {
                      return [
                        _c(
                          "v-tabs",
                          {
                            attrs: {
                              value: _vm.getTab,
                              "align-with-title": "",
                              color: _vm.getBlanc,
                            },
                            on: { change: _vm.setTab },
                          },
                          [
                            _c("v-tabs-slider", {
                              attrs: { color: _vm.getBlanc },
                            }),
                            _vm._l(_vm.getTabs, function (tab) {
                              return _c(
                                "v-tab",
                                { key: tab.key, staticClass: "white--text" },
                                [_vm._v(_vm._s(tab.title))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("v-app-bar-nav-icon", {
            style: `color: ${_vm.getBlanc}`,
            on: { click: _vm.toggleMenu },
          }),
          _c("v-spacer"),
          _c("v-toolbar-title", { style: `color: ${_vm.getBlanc}` }, [
            _vm._v(_vm._s(_vm._f("uppercase")(_vm.currentRouteName))),
          ]),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          style: `background: linear-gradient(${_vm.getBleu}, ${_vm.getTurquoisePoupin});`,
          attrs: { app: "", dark: "", "mobile-breakpoint": "1000" },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function () {
                return [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "pa-2",
                      staticStyle: { "background-color": "white" },
                    },
                    [
                      _c("v-img", {
                        attrs: { src: require("@/assets/poupin_logo.jpg") },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c("v-list-item-avatar", {
                        attrs: { color: "grey", rounded: "" },
                      }),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm._f("capitalize")(_vm.userInSession.prenom)
                              ) +
                                " " +
                                _vm._s(
                                  _vm._f("capitalize")(_vm.userInSession.nom)
                                )
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(
                                _vm._f("capitalize")(_vm.userInSession.role)
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c("v-divider"),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm.isAdmin ||
              _vm.isPoseur ||
              _vm.isConducteur ||
              _vm.isPoseurReno
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-home-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Accueil")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin || _vm.isPoseur || _vm.isPoseurReno
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/times" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-clock-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Mes horaires")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin || _vm.isPoseur || _vm.isPoseurReno
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/order" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-cart-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Commande de matériel"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin || _vm.isDirection || _vm.isBE
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/direction" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-hard-hat")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Chantiers et interventions"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin || _vm.isDirection || _vm.isBE
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/ordonnancement" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-chart-gantt")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Ordonnancement")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin || _vm.isGestionnaire || _vm.isDirection
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/manageTimes" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-clock-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Gestion des horaires"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin ||
              _vm.isGestionnaire ||
              _vm.isConducteur ||
              _vm.isPoseur ||
              _vm.isPoseurReno
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/infosUtiles" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-information-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Informations utiles"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin || _vm.isGestionnaire || _vm.isLivreur
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/livraison" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-truck-fast-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Livraison")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin || _vm.isGestionnaire || _vm.isConducteur
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/PageLeaflet" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v("mdi-map-marker-multiple-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("MAPS / Mes interventions"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/admin" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-cog-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Administration")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            [
              _c(
                "v-btn",
                { attrs: { block: "", text: "" }, on: { click: _vm.logout } },
                [
                  _c("span", [_vm._v("Se déconnecter ")]),
                  _c("v-spacer"),
                  _c("v-icon", [_vm._v("mdi-logout-variant")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }