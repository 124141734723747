<template>
  <div>
    
    <l-map style="height: 60em; width: 130em" 
    :zoom="zoom" 
    :center="center"
    ref="lmapComponent"
     >
      <l-tile-layer :url="url" />
        <l-marker
          v-for="(coord, index) in listCoordonnees"
          :key="index"
          :lat-lng="[coord.CoordonneeX, coord.CoordonneeY]"
        >
          <l-icon
            :icon-size="[32, 32]"
            :icon-anchor="[16, 37]"
            :icon-url="
              'https://raw.githubusercontent.com/Joachim53000/ScratBin/master/' +
              coord.couleur +
              '.png'
            "
          ></l-icon>
          <l-popup>
            <div>
              <h2>N° devis : {{ coord.Compteur }}</h2>
              <div v-if="coord.Name != null">
                <p style="color: green">{{ coord.Name }}</p>
              </div>
              <div v-else>
                <h3 style="color: red">Il n'y a pas de nom</h3>
              </div>
                <v-btn
                  text
                  style="min-width:0px"
                  tile
                  width="16%"
                  @click="openGoogleMap(coord)"
                  ><v-icon>mdi-google-maps</v-icon></v-btn>            
              <div v-if="coord.Rue != null">
                <p>Ville : {{ coord.Ville }}</p>
                <p>Code postal : {{ coord.CodePostal }}</p>
                <p>Rue : {{ coord.Rue }}</p>
              </div>
              <div v-else>
                <h3 style="color: red">Il n'y a pas d'adresse</h3>
              </div>
            </div>
          </l-popup>
        </l-marker>        
    </l-map>   
  </div>
</template>
<script>

import { LMap, LTileLayer, LMarker, LIcon, LPopup  } from 'vue2-leaflet';
export default {
  components: {
    LMap,        
    LTileLayer,
    LMarker,    
    LIcon, 
    LPopup
  },

  data() {
    return {
      zoom: 10,
      center: [48.05669084652626, -0.9589337175435984],
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      listCoordonnees: [],
      iconSize: [32, 32],
      iconAnchor: [16, 37],
    };
  },

  // lmapComponent = la balise ref de ma 'lmap'
  mounted(){
    const map = this.$refs.lmapComponent.mapObject;
    map.addControl(new window.L.Control.Fullscreen());
  },

  methods: {

  dynamicSize() {
    return [this.iconSize, this.iconSize * 1.15];
  },
  dynamicAnchor() {
    return [this.iconSize / 2, this.iconSize * 1.15];
  },

  openGoogleMap(coord) {
    window.open(
      "https://www.google.com/maps/search/" + coord.CoordonneeX + "," + coord.CoordonneeY + "?entry=ttu",
      "_blank"
    );
  },  

  LoadIcons() {
    // Cette méthode est appelée lorsque le composant est monté et listCoordonnees est initialisée.
    console.log(this.listCoordonnees, "this.listCoordonnees")
    this.listCoordonnees.forEach((element) => {
      switch (element.TypeInter) {
        case 1:
          element.couleur = "yellow";
          break;
        case 2:
          element.couleur = "pink";
          break;
        case 3:
          element.couleur = "orange";
          break;
        case 4:
          element.couleur = "purple";
          break;
        case 5:
          element.couleur = "blue";
          break;
        case 6:
          element.couleur = "white";
          break;
      }

      if (element.IsReady) {
        element.couleur += "-green";
      } else {
        element.couleur += "-red";
      }
    });
  }

  },


}
</script>
