<template>
  <v-container>
    <intervention-component
      v-if="isPoseur || isAdmin || isPoseurReno"
    ></intervention-component>
    <chantier-component v-if="isConducteur || isAdmin"></chantier-component>
    <!--<div v-if="windowWidth < 1000">
      <intervention-component></intervention-component>
    </div>
    <div v-else>
      <intervention-component></intervention-component>
    </div>-->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import InterventionComponent from "../components/intervention/InterventionComponent";
import ChantierComponent from "@/components/chantier/ChantierComponent";

export default {
  name: "Home",
  components: {
    ChantierComponent,
    InterventionComponent,
  },
  methods: {
    wololo() {
      this.$store.dispatch("time/checkTest");
    },
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin", "getOrange"]),

    ...mapGetters("auth", {
      isAdmin: "isAdmin",
      isConducteur: "isConducteur",
      isPoseur: "isPoseur",
      isPoseurReno: "isPoseurReno",
      userInSession: "userInSession",
    }),

    ...mapGetters("media", {
      windowWidth: "getWindowWidth",
    }),
  },
};
</script>
